import React, { useState, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import loadable from "@loadable/component";
import {
  fetchReviewsPreview,
  selectReviewsPreviewStatus,
} from "../features/reviews/reviewsSlice";
import { selectLocalizationData } from "../features/localization/localizationSlice";
import { STATUS } from "../../shared/enums";
import FallbackPlaceholder from "../components/common/FallbackPlaceholder";
import { fetchPricesSchema, selectPricesSchemaStatus } from "../features/price-schema/prices-schema-slice";

const [
  HeroAirSliderChristmas,
  AirbliviousVideo,
  SpoilerAlert,
  RejoiceVideo,
  MilaIntro,
  VideoContent,
  AirInsights,
  MilaOverlay,
  MilaChart,
  // TextBanner,
  MilaShowcase,
  // TextBlock,
  TwoSize,
  BackgroundTab,
  // PersonalizeFilter,
  AllMilaFilters,
  AwardsCredit,
  MilaFeeds,
  MilaTimeline,
  MilaOrder,
  MilaComparison,
] = [
  "HeroAirSliderChristmas",
  "AirbliviousVideo",
  "SpoilerAlert",
  "RejoiceVideo",
  "MilaIntro",
  "VideoContent",
  "AirInsights",
  "MilaOverlay",
  "MilaChart",
  // "TextBanner",
  "MilaShowcase",
  // "TextBlock",
  "TwoSize",
  "BackgroundTab",
  // "PersonalizeFilter",
  "AllMilaFilters",
  "AwardsCredit",
  "MilaFeeds",
  "MilaTimeline",
  "MilaOrder",
  "MilaComparison",
].map((component) =>
  loadable(() => import(`../components/landing-refresh/${component}`))
);

const LandingAir = ({
  route,
  headerRef,
  topBarRef,
  isOrder,
  setIsOrder,
  setIsMenuActive,
}) => {
  const dispatch = useDispatch();
  const localization = useSelector(selectLocalizationData);
  const pricesSchemaStatus = useSelector(selectPricesSchemaStatus);
  const reviewsStatus = useSelector(selectReviewsPreviewStatus);
  const { PREFETCHED, SUCCEEDED } = STATUS;
  const [topBarHeight, setTopBarHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (![PREFETCHED, SUCCEEDED].includes(reviewsStatus)) {
      dispatch(fetchReviewsPreview());
    }
  }, []);

  useEffect(() => {
    if (![PREFETCHED, SUCCEEDED].includes(pricesSchemaStatus)) {
      dispatch(fetchPricesSchema(localization));
    }
  }, [ localization ]);

  const updateOffsetHeights = useCallback(() => {
    if (topBarRef?.current) {
      setTopBarHeight(topBarRef.current.offsetHeight);
    }
    if (headerRef?.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, [topBarRef, headerRef]);

  useEffect(() => {
    updateOffsetHeights();
    window.addEventListener("resize", updateOffsetHeights);
    return () => {
      window.removeEventListener("resize", updateOffsetHeights);
    };
  }, [updateOffsetHeights]);

  const handleBuyNow = (e) => {
    e.preventDefault();
    setIsOrder(!isOrder);
    setIsMenuActive(false);
  };

  const comparison = {
    title: "She brings unmatched power-to-size performance.",
    subTitle:
      "Most purifiers are so much bigger than you realize... and you have no idea until they’re in your living room, towering over the family dog.",
    description: (
      <>
        <p>
          Mila delivers over 60% more power per pound, at half the size of your
          average air purifier. BOOM.
        </p>
      </>
    ),
  };

  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
        <style>{`:root { --offsetTop: ${
          topBarHeight + headerHeight
        }px; }`}</style>
      </Helmet>
      <HeroAirSliderChristmas
        handleBuyNow={handleBuyNow}
        localization={localization}
        fallback={<FallbackPlaceholder />}
      />
      <AirbliviousVideo />
      <SpoilerAlert />
      <RejoiceVideo />
      <MilaIntro />
      <AirInsights />
      <MilaComparison {...comparison} />
      <MilaShowcase />
      <VideoContent />
      <div className="overlay-wrapper">
        <MilaOverlay />
        <MilaChart />
      </div>
      {/* <TextBlock /> */}
      <TwoSize localization={localization} />
      <BackgroundTab />
      <AllMilaFilters />
      {/* <PersonalizeFilter /> */}
      {/* <TextBanner /> */}
      <AwardsCredit />
      <MilaFeeds />
      <MilaTimeline localization={localization} />
      <MilaOrder localization={localization} />
    </>
  );
};

export default {
  component: LandingAir,
};
