import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import {
  FILTER_NAMES_STANDALONE, FILTER_TYPES, FILTER_TYPES_STANDALONE, STATUS,
} from '../../shared/enums';
import { selectLocalizationData } from '../features/localization/localizationSlice';
import FallbackPlaceholder from '../components/common/FallbackPlaceholder';
import { fetchPricesSchema, selectPricesSchemaStatus } from "../features/price-schema/prices-schema-slice";

const FilterBillboard = loadable(() => import('../components/filter-standalone/FilterBillboard'));
const FilterOrder = loadable(() => import('../components/filter-standalone/FilterOrder'));
const FilterFeature = loadable(() => import('../components/filter-standalone/FilterFeature'));
const FilterDetail = loadable(() => import('../components/filter-standalone/FilterDetail'));
const FilterCard = loadable(() => import('../components/filter-standalone/FilterCard'));

/**
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */

const FilterStandalone = props => {
  const {
    staticContext,
    match: {
      params: { type },
    },
  } = props;

  const dispatch = useDispatch();
  const localization = useSelector(selectLocalizationData);
  const status = useSelector(selectPricesSchemaStatus);
  const { PREFETCHED, SUCCEEDED } = STATUS;

  staticContext.bodyClass = 'filter-single';

  const page = Object.keys(FILTER_TYPES_STANDALONE)
    .find(key => FILTER_TYPES_STANDALONE[key] === type);

  useEffect(() => {
    if (![ PREFETCHED, SUCCEEDED ].includes(status)) {
      dispatch(fetchPricesSchema(localization));
    }
    return undefined;
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Mila Air Purifier:
          {' '}
          {FILTER_NAMES_STANDALONE[page] || FILTER_NAMES_STANDALONE.BB}
        </title>
      </Helmet>
      <div className='filter-single__wrapper filter-single__wrapper-standalone' id={`${FILTER_TYPES[page]}-modal`}>
        <FilterBillboard fallback={<FallbackPlaceholder />} page={page} />
        <div className='filter-content'>
          {[ PREFETCHED, SUCCEEDED ].includes(status) && (<FilterOrder page={page} />)}
          <FilterFeature page={page} />
          <FilterDetail page={page} />
          <FilterCard />
        </div>
      </div>
    </>
  );
};

FilterStandalone.defaultProps = {
  staticContext: {},
};

export default { component: FilterStandalone };
