import React from 'react';
import { FILTER_TYPES } from '../../shared/enums';
import Filter from './Filter';
import NotFound from './NotFound';
import { fetchPricesSchema } from "../features/price-schema/prices-schema-slice";

const FilterWrapper = props => {
  const {
    match: {
      params: { type },
    },
  } = props;

  const Wrapper = Object.values(FILTER_TYPES).includes(type) ? Filter.component : NotFound.component;

  return (
    <Wrapper {...props} />
  );
};

const loadData = store => {
  const { localization: { data }} = store.getState();
  const actions = [
    fetchPricesSchema(data),
  ];
  return Promise.all(actions.map(action => store.dispatch(action)));
};

export default
{
  component: FilterWrapper, loadData,
};
