import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { FILTER_NAMES, FILTER_TYPES, STATUS } from '../../shared/enums';
import { selectLocalizationData } from '../features/localization/localizationSlice';
import FallbackPlaceholder from '../components/common/FallbackPlaceholder';
import {
  fetchPricesSchema, selectPricesSchemaData, selectPricesSchemaStatus
} from "../features/price-schema/prices-schema-slice";

const FilterBillboard = loadable(() => import('../components/filter/FilterBillboard'));
const FilterOrder = loadable(() => import('../components/filter/FilterOrder'));
const FilterFeature = loadable(() => import('../components/filter/FilterFeature'));
const FilterDetail = loadable(() => import('../components/filter/FilterDetail'));
const FilterCard = loadable(() => import('../components/filter/FilterCard'));
const Microdata = loadable(() => import('../components/filter/Microdata'));

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const Filter = props => {
  const {
    staticContext,
    match: {
      params: { type },
    },
  } = props;

  const dispatch = useDispatch();
  const localization = useSelector(selectLocalizationData);
  const status = useSelector(selectPricesSchemaStatus);
  const { PREFETCHED, SUCCEEDED } = STATUS;

  staticContext.bodyClass = 'filter-single';

  const page = Object.keys(FILTER_TYPES)
    .find(key => FILTER_TYPES[key] === type);

  useEffect(() => {
    if (![ PREFETCHED, SUCCEEDED ].includes(status)) {
      dispatch(fetchPricesSchema(localization));
    }
    return undefined;
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Mila Air Purifier:
          {' '}
          {FILTER_NAMES[page] || FILTER_NAMES.BB}
        </title>
      </Helmet>
      <div className='filter-single__wrapper' id={`${FILTER_TYPES[page]}-modal`}>
        <FilterBillboard fallback={<FallbackPlaceholder />} page={page} />
        <div className='filter-content'>
          {[ PREFETCHED, SUCCEEDED ].includes(status) && (<FilterOrder page={page} />)}
          <FilterFeature page={page} />
          <FilterDetail page={page} />
          <FilterCard />
        </div>
      </div>
      {[ PREFETCHED, SUCCEEDED ].includes(status) && (<Microdata page={page} />)}
    </>
  );
};

Filter.defaultProps = {
  staticContext: {},
};

export default { component: Filter };
