export const FIELDS= {
  CURRENCY_SIGN: { field: 'CURRENCY_SIGN', type: 'str'},
  DESCRIPTION: { field: 'DESCRIPTION', type: 'str'},
  ENABLE_DISCOUNT: { field: 'ENABLE_DISCOUNT', type: 'bool'},
  UNIT_DISCOUNT_PRICE: { field: 'UNIT_DISCOUNT_PRICE', type: 'int'},
  UNIT_PRICEx1: { field: 'UNIT_PRICEx1', type: 'int'},
  UNIT_PRICEx2: { field: 'UNIT_PRICEx2', type: 'int'},
  UNIT_PRICEx3: { field: 'UNIT_PRICEx3', type: 'int'},
  FILTER_PRICE: { field: 'FILTER_PRICE', type: 'int'},
  VARIANT: { field: 'VARIANT', type: 'str'},
}
