import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { STATUS } from '../../shared/enums';
import { selectLocalizationData } from '../features/localization/localizationSlice';
import FallbackPlaceholder from '../components/common/FallbackPlaceholder';
import { fetchPricesSchema, selectPricesSchemaStatus } from "../features/price-schema/prices-schema-slice";

const FeaturedContent = loadable(() => import('../components/why-mila-landing/FeaturedContent'));
const HeroFancyHeadingAlt = loadable(() => import('../components/why-mila-landing/HeroFancyHeadingAlt'));
const OrderBlock = loadable(() => import('../components/common/OrderBlock'));

/**
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */

const WhyMilaLanding = props => {
  const { route } = props;

  const dispatch = useDispatch();
  const localization = useSelector(selectLocalizationData);
  const status = useSelector(selectPricesSchemaStatus);
  const { PREFETCHED, SUCCEEDED } = STATUS;

  useEffect(() => {
    if (status !== STATUS.PREFETCHED) {
      dispatch(fetchPricesSchema(localization));
    }
    return undefined;
  }, []);

  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
      </Helmet>
      <HeroFancyHeadingAlt fallback={<FallbackPlaceholder />} />
      <FeaturedContent />
      {[ PREFETCHED, SUCCEEDED ].includes(status) && (<OrderBlock />)}
    </>
  );
};

const loadData = store => {
  const { localization: { data }} = store.getState();
  const actions = [
    fetchPricesSchema(data),
  ];
  return Promise.all(actions.map(action => store.dispatch(action)));
};

export default { component: WhyMilaLanding, loadData };
