import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { selectLocalizationData } from '../features/localization/localizationSlice';
import { STATUS } from '../../shared/enums';
import { fetchPricesSchema, selectPricesSchemaStatus } from "../features/price-schema/prices-schema-slice";

import FallbackPlaceholder from '../components/common/FallbackPlaceholder';

const HeroBackgroundImage = loadable(() => import('../components/our-story/HeroBackgroundImage'));
const SbsicBlock = loadable(() => import('../components/our-story/SbsicBlock'));
const GridCard = loadable(() => import('../components/our-story/GridCard'));
const OverlayBlock = loadable(() => import('../components/our-story/OverlayBlock'));
const OrderBlock = loadable(() => import('../components/common/OrderBlock'));

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const OurStory = props => {
  const { route } = props;

  const dispatch = useDispatch();
  const localization = useSelector(selectLocalizationData);
  const status = useSelector(selectPricesSchemaStatus);
  const { PREFETCHED, SUCCEEDED } = STATUS;

  useEffect(() => {
    if (![ PREFETCHED, SUCCEEDED ].includes(status)) {
      dispatch(fetchPricesSchema(localization));
    }
    return undefined;
  }, []);

  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
      </Helmet>
      <HeroBackgroundImage fallback={<FallbackPlaceholder />} />
      <SbsicBlock />
      <GridCard />
      <OverlayBlock />
      {[ PREFETCHED, SUCCEEDED ].includes(status) && (<OrderBlock />)}
    </>
  );
};

const loadData = store => {
  const { localization: { data }} = store.getState();
  const actions = [
    fetchPricesSchema(data),
  ];
  return Promise.all(actions.map(action => store.dispatch(action)));
};

export default { component: OurStory, loadData };
