import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import {STATUS} from '../../../shared/enums';
import { addGlobalError } from '../root/rootSlice';
import { FIELDS } from "./prices-schema-fields";

export const initialState = {
  data: {
    BB: {},
    BS: {},
    CC: {},
    HW: {},
    MB: {},
    OR: {},
    RP: {},
  },
  status: STATUS.IDLE,
  error: null,
};

export const fetchPricesSchema = createAsyncThunk('pricesSchema/fetchSchema', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const { region = 'US' } = payload;
    const fields = Object.keys(FIELDS).join(',');
    const response = await axios.get(`/prices-schema?fields=${fields}`).catch(console.error);
    const products = response?.data[region];

    const schema = {};
    // console.log('PRODUCTS', products);
    for (const productKey of Object.keys(initialState.data)) {
      schema[productKey] = Object.entries(products[productKey] || {}).reduce((acc, [key, value]) => {
        switch (FIELDS[key]?.type) {
          case 'int':
            acc[key] = parseInt(value, 10);
            break;
          case 'bool':
            acc[key] = value.toLowerCase() === 'true' || value === '1';
            break;
          case 'str':
            acc[key] = value;
            break;
          default:
        }
        return acc;
      }, {});
    }
    // console.log('SCHEMA', schema);
    return products;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {setBannersIdle: *, clearBanners: *}, string>}
 */
export const pricesSchemaSlice = createSlice({
  name: 'pricesSchema',
  initialState,
  reducers: {
    clearSchema: state => {
      state.data = initialState.data;
      state.status = initialState.status;
      state.error = initialState.error;
    },
    setSchemaIdle: state => {
      state.status = initialState.status;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPricesSchema.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(fetchPricesSchema.fulfilled, (state, action) => {
        state.status = IS_SERVER ? STATUS.PREFETCHED : STATUS.SUCCEEDED;
        state.data = action.payload;
        state.error = initialState.error;
      })
      .addCase(fetchPricesSchema.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const { clearSchema, setSchemaIdle, setRegion, setProductType } = pricesSchemaSlice.actions;

export const selectPricesSchemaData = state => state.pricesSchema.data;
export const selectPricesSchemaStatus = state => state.pricesSchema.status;
export const selectPricesSchemaError = state => state.pricesSchema.error;

export default pricesSchemaSlice.reducer;
